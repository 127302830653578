.logo-psy{
    background-image: url('../../../public/LOGO-PSICOLOGIA-2.png');
    background-size: contain;
    background-repeat: no-repeat;
    width: 90px;
    height: 90px;
    margin-right: 10px;
    margin-top: 0px;
    margin-left: 42px;
}

.header
{
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.sottotitolo-header{
    /* background-color: #eee5dea4;
    background-color: #efe7f8; */
    /* padding: 0.5px 23px;
    border-radius: 0 0 20px 20px; */
    letter-spacing: 2px;
    font-size: 30px;
    font-variant: small-caps;
    letter-spacing: 10px;
    padding: 0px;
    /* border: 1px solid black; */
    text-align: center;
    position: relative;
    top: -10px;
}

div h1{
    letter-spacing: 2px;
    font-size: 35px;
}

@media (max-width: 768px) {
    .header{
        display: none;
    }
}
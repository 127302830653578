.immagine-profilo {
  width: 400px;
  height: 400px;
  margin-right: 100px;
  margin-left: 8px;
  margin-top: 40px;
  align-self: flex-start;
  /* border-radius: 50%; */
  background-image: url('./../../../public/pinna-psicologa-foto.JPG');
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;

}


#titolo-about{
  margin-left: 60px;
}

.sez-chi-sono {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.presentazione {
  display: flex;
  flex-direction: row;
  justify-content: center;
  line-height: 1.7;
  letter-spacing: 10px;
  font-size: 19px;
  text-align: justify;
  margin-top: 40px;
}

.descrizione {
  width: 50%;
  align-self: center;
  letter-spacing: 7px;
}

.metodo {
  font-weight: 100;
}

#call-to-action{
  display: flex;
  flex-direction: column;
}

#scrivimi {
  margin-top: 30px;
  background-color: #8c83b0;
  padding: 10px 30px;
  font-size: large;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-transform: uppercase;
  width:79%;
  letter-spacing: 3px;
  margin-left: 8px;
  font-weight: 800;


}


#scrivimi:hover {
  background-color: #ecebee;
  color: #8c83b0;
  border: 1.3px solid #8c83b0;
  ;
  letter-spacing: 3.7px;
}

#scrivimi:hover>.bottone-contatti-about {
  color: #8c83b0;
  ;
  letter-spacing: 3.7px;
}

.bottone-contatti-about {
  text-decoration: none;
  color: #ecebee;
}

.bottone-contatti-about:hover {
  color: #8c83b0;
}


@media (max-width: 768px) {

  #titolo-about{
    margin-left: 0px;
  }

  /* Adatta le dimensioni dell'immagine per schermi più piccoli */
  .immagine-profilo {
    width: 200px;
    /* Occupa tutta la larghezza del contenitore */
    height: 185px;
    /* Adatta l'altezza proporzionalmente alla larghezza */
    border-radius: 120%;
    margin-right: 15px;
    align-self: center;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .sez-chi-sono {
    margin-top: 50px;
  }

  .descrizione {
    width: 90%;

  }

  .presentazione {
    flex-direction: column;
    align-items: center;

  }

  #scrivimi {
    align-self: center;
    margin-top: 10px;
    margin-left: 0;
    letter-spacing: 2;
    width: 100%;

  }

  h2 {
    margin-left: 0px;
  }
}
/* Stili per il form di contatto */
.contact-form {
    background-color: #fff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 600px; 
    margin: 0 auto; /* Centrato orizzontalmente sulla pagina */
    line-height: 1.7;
  letter-spacing: 2.5px;
  text-align: justify;
  text-transform: uppercase;
 color: #463f3a;
 margin-top: 40px;
 margin-bottom: 30px;
  }
  
  .contact-form label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
  }
  
  .contact-form input,
  .contact-form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 16px;
    border: 1px solid #8c83b0;
    border-radius: 4px;
    box-sizing: border-box;
  }


  
  .contact-form button {
    font-size: large;
    background-color: #7b6f67;
    background-color: #8c83b0;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    letter-spacing: 3px;
    font-weight: 600;
    text-transform: uppercase;
  }
  
  .contact-form button:hover {
    background-color: #ecebee;
    color: #8c83b0;
    border: 1.3px solid #8c83b0;;
    letter-spacing: 3.7px;

  }
  
/* Stili per il footer */
footer {
  background-color: #615f81;
  color: #F1F1F1;
  padding: 20px;
  text-align: left;
  line-height: 1.5;
  letter-spacing: 2px;
  position: absolute;
  /* o absolute */
  bottom: 1;
  width: 100%;

}

footer div {
  margin-bottom: 15px;
}

/* Stile per i link nel footer */
footer a {
  color: #f3e5da;
  text-decoration: none;
}

footer a:hover {
  text-decoration: underline;
}

.copyright {
  margin-top: 50px;
  text-align: right;
  font-size: 14px;

}

.powered {
  font-size: 15px;
  margin-top: 50px;
  text-align: center;
}
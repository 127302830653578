@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');


.i-miei-servizi{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #faf9ff;
    padding: 50px;
    border-bottom: 0.5px solid #615F81;
    /* font-variant: small-caps; */
    
}


.icona{
    border: 1px solid #615F81;
    background-color: #F1F1F1;
    border-radius: 50%;
    padding: 30px;
}

#servizi-icone{
    
    font-family: 'Roboto', sans-serif;
    margin-top: 30px;
}



.descrizione{
font-size: 17px;
    line-height: 1.7;
    letter-spacing: 1px;
    text-align: justify;
}

#Servizi h2{
    margin-right: 30px;
}

.elenco-servizi{
    list-style-type: none;
}
